<template>
    <div class="personnel_xqq">
         请耐心等待

    </div>
</template>
<script>
    export default {
      data() {
        return {

        }
      },
      created(){
        // this.$store.commit("Guid", this.$route.query.DataGuid);
        console.log(this.$route.query,'路由参数');
        this.$router.push({ path: "/DiscussionArea",query:{DataGuid:this.$route.query.DataGuid}});
      }
    }
  </script>
  <style lang="less" scoped>


  </style>
